import { FC, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  Autocomplete,
  TextField,
  Button,
  Stack,
  Divider,
  Dialog,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Box,
  Paper,
  useTheme,
} from "@mui/material";
import {
  AngleRightIcon,
  CirclePlusIcon,
} from "../components/svgicons/SvgIcons";
import FancyTooltip from "../components/utils/FancyTooltip";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useApp } from "../hooks/useApp";
import { useStep } from "../hooks/useStep";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { createClientsTypes, user } from "../types/types";
import { data } from "../resources/data/data";
import {
  createRequest,
  updateRequest,
  getRequestDocumentClient,
} from "../services/ManagementClientsService";
import { changeState } from "../services/StageAndFlowService";
import { properties } from "../utils/Properties_es";
import ExecutiveStage from "./stage/ExecutiveStage";
import ConfirmChangeState from "../components/utils/ConfirmChangeState";
import Observations from "../components/utils/Observations";
import ResourceAccess from "../components/security/ResourceAccess";
import DocumentStage from "./stage/DocumentsStage";

const CreatedClient = () => {
  const theme = useTheme();
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
    detailsMsg,
    setDetailsMsg,
  } = useApp();
  const {
    setActiveStep,
    client,
    handleFetchClient,
    handleReturn,
    usersData,
    dataBranch,
  } = useStep();
  const { id } = useParams();
  const navigate = useNavigate();
  const [documentByClient, setDocumentByClient] = useState<any | null>(null);
  const [documentByClientAnalysis, setDocumentByClientAnalysis] = useState<
    any | null
  >(null);
  const [openNotesDialog, setOpenNotesDialog] = useState(false);

  const defaultValues: createClientsTypes = {
    id: client?.id ? client.id : "",
    code: client?.code ? client.code : 0,
    creationtDate: new Date(),
    creatorUser: client?.creatorUser ? client.creatorUser : authInfo?.username,
    executive: client?.executive ? client.executive : [],
    modificationDate: new Date(),
    modifierUser: client?.modifierUser ? client.modifierUser : "",
    personType: client?.personType ? client.personType : "Física",
    stage: client?.stage ? client.stage : "Identificación",
    office: client?.office ?? [],
    typeAccount: client?.typeAccount ?? "",
    fileForm: client?.fileForm ? client.fileForm : "",
    clientStatus: client?.clientStatus ? client.clientStatus : "1",
    accounts: (client?.accounts && client.accounts) ?? [
      {
        client: client?.id ?? 0,
        name: "",
        identification: "",
        email: "",
        risk: "",
      },
    ],
  };
  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    reset,
    resetField,
    setValue,
  } = useForm<createClientsTypes>({
    defaultValues,
    //resolver: yupResolver(validation),
  });
  const [requiredDocuments, setRequiredDocuments] = useState(false);
  const [riskMore, setriskMore] = useState(null);
  const [filterExecutive, setFilterExecutive] = useState<user[] | null>(null);
  const watch_personType: any = watch("personType");
  const watch_riskType: any = watch("accounts");
  const watch_typeAccount: any = watch("typeAccount");
  const watch_signatureType: any = watch("fileForm");
  const watch_office: any = watch("office");

  const {
    fields: addAccountsField,
    append: addAccountsAppend,
    remove: addAccountsRemove,
  } = useFieldArray({
    control,
    name: "accounts",
    rules: {
      minLength: 1,
    },
  });

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line
  }, [client]);

  useLayoutEffect(() => {
    const dataInit = async () => {
      if (id) {
        handleFetchClient && handleFetchClient(id);
      }
    };
    dataInit();
    // eslint-disable-next-line
  }, []);

  const handleFetchDocumentsRole = async (
    id: string,
    personType: string | null,
    riskType: string | null,
    role: number,
    signatureType: string
  ) => {
    try {
      let documentByClientByRole: any = await getRequestDocumentClient(
        id,
        personType,
        riskType,
        role,
        signatureType
      );
      /* if (documentByClientByRole) {
        setDocumentByClient(documentByClientByRole);
      } */
      return documentByClientByRole;
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  useEffect(() => {
    let riskMoreTall: any = null;
    watch_riskType.filter((c: any) => {
      if (c.risk.toLowerCase() === data.risk[3].toLowerCase()) {
        riskMoreTall = c;
        return true;
      } else if (c.risk.toLowerCase() === data.risk[2].toLowerCase()) {
        riskMoreTall = c;
        return true;
      } else if (c.risk.toLowerCase() === data.risk[1].toLowerCase()) {
        riskMoreTall = c;
        return true;
      } else {
        riskMoreTall = c;
        return true;
      }
    });
    //@ts-ignore
    riskMoreTall !== null &&
      riskMoreTall?.id > 0 &&
      setriskMore(riskMoreTall.risk);

    const dataDocuments = async () => {
      if (id && riskMoreTall !== null && riskMoreTall?.id > 0) {
        const loadDocuments = await handleFetchDocumentsRole(
          id,
          watch_personType,
          riskMoreTall.risk,
          9,
          watch_signatureType
        );
        setDocumentByClient(loadDocuments);

        const loadDocumentsAnalysis = await handleFetchDocumentsRole(
          id,
          watch_personType,
          riskMoreTall.risk,
          24,
          watch_signatureType
        );
        setDocumentByClientAnalysis(loadDocumentsAnalysis);
      }
    };
    dataDocuments();
    // eslint-disable-next-line
  }, [watch_personType, watch_riskType]);

  useEffect(() => {
    if (watch_office.id !== 0) {
      const filterUser: user[] | undefined = usersData?.filter(
        (item: user) => Number(item.userBranchOffice) === watch_office.id
      );
      if (filterUser) {
        if (!client?.executive || client?.office.id !== watch_office.id) {
          setValue("executive", {
            id: 0,
            username: "",
            firstName: "",
            lastName: "",
            phone: 0,
            status: "",
            userRole: "",
            activationCode: "",
            userBranchOffice: "",
          });
        }
        setFilterExecutive(filterUser);
      }
    }
  }, [watch_office]);

  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    console.log(event);
    const modalAction = event.currentTarget.getAttribute("data-name");

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        //@ts-ignore
        modalObject: null,
      });
  };

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleChangeState = async () => {
    let changeStateData: any = false;
    try {
      changeStateData = await changeState({
        clientId: Number(id),
        statusId: 1,
        user: authInfo?.username,
      });
      if (!changeStateData) {
        setErrorMsg && setErrorMsg("Error al actualizar cliente");
        setLoading && setLoading(false);
        return;
      }
      handleReturn && handleReturn();
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleCloseNotes = () => {
    setOpenNotesDialog(false);
  };

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    await handleChangeState();
  };

  return (
    <>
      <Box
        sx={{ px: 3.75, pt: 12, pb: 12, mr: "auto", ml: "auto" }}
        maxWidth="lg"
      >
        <Paper
          sx={{
            padding: {
              xs: `${theme.spacing(10)} ${theme.spacing(5)}`,
              md: theme.spacing(10),
            },
            borderRadius: theme.spacing(4),
          }}
        >
          <form>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Typography variant="h3" color="greyDue.dark" sx={{ mb: 4 }}>
                Cliente
                <br />
                <small style={{ fontSize: "1rem" }}>
                  <b>Creado por:</b> {client?.creatorUser}
                </small>
                <br />
                <small style={{ fontSize: "1rem" }}>
                  <b>Modificado por:</b> {client?.modifierUser}
                </small>
              </Typography>
              <Button
                variant="contained"
                color="primary"
                type="button"
                disabled={!ResourceAccess(true, "STARTCLIENTUPDATE:WRITE")}
                onClick={handleSubmit(onSubmit)}
              >
                Actualizar
              </Button>
            </Stack>
            <Grid container spacing={6} maxWidth="lg" alignItems="flex-end">
              <Grid item md={6} sm={6} xs={12}>
                <Typography
                  variant="h5"
                  color="greyDue.dark"
                  sx={{ fontWeight: 400, fontSize: "1.25rem" }}
                >
                  <AngleRightIcon
                    sx={{
                      marginRight: "0px",
                      color: "secondary.main",
                      transform: "translateY(3px)",
                      fontSize: "1.25rem",
                    }}
                  />{" "}
                  Datos del cliente
                </Typography>
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <Controller
                  control={control}
                  name="office"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                      }}
                      options={
                        dataBranch ?? [
                          {
                            address: "",
                            id: 0,
                            name: "",
                            notaryId: 0,
                            tableName: "",
                            type: "",
                          },
                        ]
                      }
                      getOptionLabel={(option: any) => option.name || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      //@ts-ignore
                      value={value}
                      disabled={
                        ResourceAccess(true, "IDENTIFICATIONSTEP:WRITE") ===
                          false ||
                        (client !== null &&
                          client?.code !== 0 &&
                          client?.code !== "0")
                      }
                      sx={{ pb: 0 }}
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="Sucursal"
                            variant="standard"
                            disabled
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Controller
                  name={"clientStatus"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth variant="standard">
                      <InputLabel>Tipo de cliente</InputLabel>
                      <Select
                        value={value}
                        {...register("clientStatus")}
                        disabled={id ? true : false}
                      >
                        <MenuItem value="">Seleccione</MenuItem>
                        <MenuItem value="1">Cliente nuevo</MenuItem>
                        <MenuItem value="6">Cliente actualización</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
            <Divider sx={{ pt: 4 }} />
            <Grid container spacing={6} maxWidth="lg">
              <Grid item md={3} sm={6} xs={12}>
                <Controller
                  name={"code"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Código del cliente"
                      type="text"
                      variant="standard"
                      fullWidth
                      value={value}
                      disabled
                      {...register("code")}
                    />
                  )}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <Controller
                  control={control}
                  name="executive"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                      }}
                      options={
                        filterExecutive ?? [
                          {
                            id: 0,
                            username: "",
                            firstName: "",
                            lastName: "",
                            phone: 0,
                            status: "",
                            userRole: "",
                            activationCode: "",
                            userBranchOffice: "",
                          },
                        ]
                      }
                      getOptionLabel={(option: any) =>
                        filterExecutive && option.firstName
                          ? option.firstName + " " + option.lastName
                          : ""
                      }
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      //@ts-ignore
                      value={value ?? []}
                      disabled={
                        ResourceAccess(true, "IDENTIFICATIONSTEP:WRITE") ===
                          false ||
                        (client !== null &&
                          client?.code !== 0 &&
                          client?.code !== "0")
                      }
                      sx={{ pb: 0 }}
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="Ejecutivo"
                            variant="standard"
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={2} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="personType"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                      }}
                      options={data.personType}
                      getOptionLabel={(option: any) => option || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option === value
                      }
                      //@ts-ignore
                      value={value}
                      disabled
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="Tipo de persona"
                            variant="standard"
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={2} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="fileForm"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                      }}
                      options={data.fileForm}
                      getOptionLabel={(option: any) => option || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option === value
                      }
                      //@ts-ignore
                      value={value}
                      disabled
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="Modalidad de Firma"
                            variant="standard"
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={2} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="typeAccount"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                      }}
                      options={
                        watch_personType === "Física"
                          ? data.typeAccount
                          : data.typeAccountLegal
                      }
                      getOptionLabel={(option: any) => option || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option === value
                      }
                      //@ts-ignore
                      value={value}
                      disabled
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="Tipo de cuenta"
                            variant="standard"
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {addAccountsField.map((field, index) => {
              return (
                <div key={index}>
                  <Divider sx={{ pt: 3, mb: 3 }} />
                  <Grid container spacing={6} maxWidth="lg">
                    <Grid item md={3} sm={6} xs={12}>
                      <Controller
                        name={`accounts.${index}.identification`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            label={
                              watch_personType === "Física" ? `Cédula` : `RNC`
                            }
                            type="text"
                            variant="standard"
                            fullWidth
                            value={value}
                            disabled
                            {...register(
                              `accounts.${index}.identification` as const
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <Controller
                        name={`accounts.${index}.name`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            label="Nombres y Apellidos"
                            type="text"
                            variant="standard"
                            fullWidth
                            value={value}
                            disabled
                            {...register(`accounts.${index}.name` as const)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <Controller
                        name={`accounts.${index}.email`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            label="Email"
                            type="mail"
                            variant="standard"
                            fullWidth
                            value={value}
                            disabled
                            {...register(`accounts.${index}.email` as const)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <Controller
                        control={control}
                        name={`accounts.${index}.risk`}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            fullWidth
                            onChange={(event, newValue: any) => {
                              onChange(newValue);
                            }}
                            options={data.risk}
                            getOptionLabel={(option: any) => option || ""}
                            isOptionEqualToValue={(option: any, value: any) =>
                              option === value
                            }
                            //@ts-ignore
                            value={value}
                            disabled
                            renderInput={(params) => {
                              const inputProps = params.inputProps;
                              inputProps.autoComplete = "new-password";
                              return (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label="Riesgo"
                                  variant="standard"
                                />
                              );
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </div>
              );
            })}

            <Grid container spacing={6} maxWidth="lg" sx={{ pt: 6, pb: 8 }}>
              {id && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-end" },
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    data-name="observation"
                    onClick={() => setOpenNotesDialog(true)}
                  >
                    Ver observaciones
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
          {id && (
            <>
              <ExecutiveStage
                personTypeData={watch_personType}
                disabledByStage={
                  ResourceAccess(true, "IDENTIFICATIONSTEP:WRITE") === false ||
                  (client?.stage?.id !== 1 && client?.stage?.id !== 6) ||
                  (client?.stage?.id === 6 && client?.status?.id !== 2)
                }
                riskTypeData={riskMore}
                documentByClient={documentByClient}
                handleFetchDocumentsRole={handleFetchDocumentsRole}
                handleFetchClient={handleFetchClient}
                client={client}
                requiredDocuments={requiredDocuments}
              />
              <DocumentStage
                personTypeData={watch_personType}
                riskTypeData={riskMore}
                documentByClient={documentByClientAnalysis}
                handleFetchDocumentsRole={handleFetchDocumentsRole}
                handleFetchClient={handleFetchClient}
                client={client}
                requiredDocuments={requiredDocuments}
                idRole="24"
                title="Categoría Análisis y Monitoreo"
              />
              <Stack
                direction="row"
                justifyContent="center"
                spacing={3}
                sx={{ mt: 4 }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleReturn}
                  data-write={ResourceAccess(true, "CHANGESTAGE:WRITE")}
                >
                  Regresar
                </Button>
              </Stack>
            </>
          )}
          {modalData?.modalType === "updatestage" && (
            <Dialog
              open={modalData.modalOpen}
              onClose={handleCancelModal}
              fullWidth
            >
              <ConfirmChangeState
                textChildren="Si cambias de estado no podras agregar o borrar los documentos cargados."
                actionButton={
                  <>
                    {" "}
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{
                        mt: 2,
                        mr: 2,
                      }}
                      name="updatestage"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Aceptar
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{
                        mt: 2,
                      }}
                      onClick={handleCancelModal}
                      autoFocus
                    >
                      Cancelar
                    </Button>{" "}
                  </>
                }
              />
            </Dialog>
          )}
        </Paper>
      </Box>

      <Dialog
        open={openNotesDialog}
        onClose={() => setOpenNotesDialog(false)}
        fullWidth
        maxWidth="lg"
      >
        <Observations id={id ? id : ""} closeModal={handleCloseNotes} />
      </Dialog>
    </>
  );
};

export default CreatedClient;
